export const appBaseURL = "/"

export const appRootId = "nuxt-app"

export const appBuildAssetsDir = "assets"

export const appCdnURL = "https://s5-edu.51cto.com/edu/training-fe/"

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"build-date","content":"Mon, 03 Mar 2025 08:28:14 GMT"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"http-equiv":"x-dns-prefetch-control","content":"on"},{"http-equiv":"Content-Type","content":"text/html;charset=utf-8"},{"name":"keywords","content":""},{"name":"description","content":""}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"dns-prefetch","href":"https://s5-edu.51cto.com/"}],"style":[],"script":[],"noscript":[],"title":"","htmlAttrs":{"lang":"zh-CN","style":""}}

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = false

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#nuxt-app'

export const viewTransition = false