// 域名映射关系
export enum MultiHostEnum {
  CHENG_DU = 'www.cd51cto.com',
  XIA_MEN = 'www.xm51cto.com',
  ZZ_NET = 'www.wuyoukaozheng.com',
  WU_HAN = 'www.51cto.net.cn',
  JIU_TIAN_YUN_CHUANG = 'www.51dcool.cn',
}

export const OriginNameMap = {
  [MultiHostEnum.WU_HAN]: '武汉',
  [MultiHostEnum.CHENG_DU]: '成都',
  [MultiHostEnum.XIA_MEN]: '厦门',
  [MultiHostEnum.ZZ_NET]: '郑州',
  [MultiHostEnum.JIU_TIAN_YUN_CHUANG]: '北京',
};

// 不同host对应配置
export const HostConfigMap = {
  [MultiHostEnum.CHENG_DU]: {
    header: {},
    footer: {
      cooperation: '无忧创想（成都）信息技术有限公司',
      copyright: '蜀ICP备2024074675号-1 川公网安备51019002006450号',
      location: {
        name: '四川成都办公地点',
        address: '中国（四川）自由贸易试验区成都高新区盛通街16号54-55栋',
        tel: '400-101-1651',
        site: 'CD51CTO.COM',
      },
      businessLicense: [],
    },
    map: 'https://s2.51cto.com/edu/training_font/net/cd-map.png',
    aboutUs: [
      '无忧创想（成都）信息技术有限公司是北京无忧创想信息技术有限公司全资子公司。',
      '51CTO（无忧创想（成都）信息技术有限公司） 成立于2021年，以服务一亿数字化人才职业成长为己任，是中国知名的数字化人才学习咨询平台和技术咨询社区，并以规模大、用户多、技术门类全等特点为市场充分认可。51CTO对中国数千万数字化人才拥有强大的影响力和服务能力，在成长过程中陆续获得挚信资本、高成资本等一线投资人的强力支持。 51CTO面向企业推出人才体系建设咨询平台。作为备受各行业龙头企业信赖的国内数字化人才综合培养创新服务机构，51CTO针对企业数字化人才队伍体系化发展、数字人才标准、数字人才评估与认证体系建设、数字资源建设、定制化人才培养等需求，通过数字人才咨询服务、AI智能测评服务、等产品与服务模式， 为企业提供数字人才发展一体化解决方案。中国百强企业中的很多与51CTO达成人才培养咨询合作，涉及行业包括互联网、电信金融、能源、制造、房地产、教育、医疗、政府等。',
    ],
    isDcoolNet: false,
  },
  [MultiHostEnum.XIA_MEN]: {
    header: {},
    footer: {
      cooperation: '无忧创想（厦门）信息技术有限公司',
      copyright: '闽ICP备2024056474号-1 闽公网安备35020602003079号',
      location: {
        name: '福建厦门办公地点',
        address: '中国（福建）自由贸易试验区厦门片区象屿路93号厦门国际航运中心C栋4层431单元H',
        tel: '400-101-1651',
        site: 'XM51CTO.COM',
      },
      businessLicense: [],
    },
    map: 'https://s2.51cto.com/edu/training_font/net/xm-map.png',
    aboutUs: [],
    isDcoolNet: false,
  },
  [MultiHostEnum.ZZ_NET]: {
    header: {},
    footer: {
      cooperation: '无忧创想（郑州）信息技术有限公司',
      copyright: '豫ICP备2024080338号-1 豫公网安备41019702003597号',
      location: {
        name: '河南郑州办公地点',
        address: '河南省郑州市高新技术产业开发区西四环206号4号楼A609',
        tel: '400-101-1651',
        site: 'WUYOUKAOZHENG.COM',
      },
      businessLicense: [],
    },
    map: 'https://s2.51cto.com/edu/training_font/net/zz-map.png',
    aboutUs: [],
    isDcoolNet: false,
  },
  [MultiHostEnum.WU_HAN]: {
    header: {},
    footer: {
      cooperation: '无忧创想（武汉）信息技术有限公司',
      copyright: '鄂公网安备 42018502005471号',
      location: {
        name: '武汉洪山办公地点',
        address: '湖北省武汉市洪山区关山大道111号光谷时代广场A座10层、16层',
        tel: '027-59533557',
        site: '51CTO.NET.CN',
      },
      businessLicense: [],
    },
    map: 'https://s2.51cto.com/images/202108/10/e0a7134c355b22b09b41bf76bddde956.png',
    aboutUs: [],
    isDcoolNet: false,
  },
  [MultiHostEnum.JIU_TIAN_YUN_CHUANG]: {
    header: {},
    footer: {
      cooperation: '北京九天云创信息技术有限公司',
      copyright: '京公网安备 11010802030431号',
      location: {
        name: '北京海淀区办公地点',
        address: '北京市海淀区中关村南一条甲一号ECO中科爱克大厦大厦6-7层',
        tel: '027-59533557',
        site: '51DCOOL.CN',
      },
      businessLicense: [
        {
          text: '营业执照',
          link: 'https://s2.51cto.com/edu/training_fe/license/51dcool_zz.jpg',
        },
        {
          text: '出版物经营许可证',
          link: 'https://s2.51cto.com/edu/training_fe/license/51dcool_xkz.jpg',
        },
      ],
    },
    map: 'https://s2.51cto.com/images/202108/10/e0a7134c355b22b09b41bf76bddde956.png',
    aboutUs: [
      '北京九天云创信息技术有限公司是北京无忧创想信息技术有限公司全资子公司。',
      '公司以服务一亿数字化人才职业成长为己任，是中国知名的数字化人才学习咨询平台和技术咨询社区，并以规模大、用户多、技术门类全等特点为市场充分认可。51CTO对中国数千万数字化人才拥有强大的影响力和服务能力，在成长过程中陆续获得挚信资本、高成资本等一线投资人的强力支持。 51CTO面向企业推出人才体系建设咨询平台。作为备受各行业龙头企业信赖的国内数字化人才综合培养创新服务机构，51CTO针对企业数字化人才队伍体系化发展、数字人才标准、数字人才评估与认证体系建设、数字资源建设、定制化人才培养等需求，通过数字人才咨询服务、AI智能测评服务、等产品与服务模式， 为企业提供数字人才发展一体化解决方案。中国百强企业中的很多与51CTO达成人才培养咨询合作，涉及行业包括互联网、电信金融、能源、制造、房地产、教育、医疗、政府等。',
    ],
    isDcoolNet: true,
  },
  default: {
    footer: {
      cooperation: '北京无忧创想信息技术有限公司',
      copyright: '默认备案12345678',
      location: {
        name: '默认办公地点',
        address: '默认办公地点',
        tel: '123456789',
        site: 'localhost',
      },
      businessLicense: [],
    },
    map: 'https://s2.51cto.com/images/202108/10/e0a7134c355b22b09b41bf76bddde956.png',
    aboutUs: [],
    isDcoolNet: false,
  },
};

export type HostConfigMapType = typeof HostConfigMap;
export type HostConfigMapValue = HostConfigMapType[keyof HostConfigMapType];

export enum IsZZNetEnum {
  IS_ZZ_NET = 1,
  NOT_ZZ_NET = 0,
}
